import React, { useState } from "react";

import { Form, FormGroup } from "react-bootstrap";

import SecondaryLanding from "../layouts/secondary-landing";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";
import StateSelectionMap from "../assets/open-an-account-state-selection-map.svg";
import Checkbox from "../components/custom-widgets/checkbox";
import Button from "../components/custom-widgets/button";
import BestBanksDefault from "../components/best-banks/best-banks-default";
import NotificationAlert from "../components/notifications/notification-alert";

const OpenAnAccount = () => {
  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Open an Account"
    }
  ];

  const SEOData = {
    title: "Thank you for your interest in opening an account with WaFd Bank.",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Thank you for your interest in opening an account with WaFd Bank."
      },
      {
        name: "description",
        property: "og:description",
        content: "Thank you for your interest in opening an account with WaFd Bank."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/open-an-account"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const wafdStates = [
    "Arizona",
    "California",
    "Idaho",
    "Nevada",
    "New Mexico",
    "Oregon",
    "Texas",
    "Utah",
    "Washington"
  ];

  const newAccountOpeningStates = ["Idaho", "Nevada"];

  const INITAL_CHECKBOXES = Array(wafdStates.length).fill(false);

  const [stateClick, setStateClick] = useState(INITAL_CHECKBOXES);

  const [openAccountUrl, setOpenAccountUrl] = useState("https://forms.fivision.com/wafdbank/oav3?");

  /**
   * A user can check only one checkbox at a time and we
   * have to uncheck all the other checkboxes. So, this logic will
   * make sure all other checkboxes are unchecked.
   */
  const handleStateClick = function (index) {
    let newState = [...INITAL_CHECKBOXES];
    newState[index] = !stateClick[index];
    setStateClick(newState);

    if (newAccountOpeningStates.includes(wafdStates[index])) {
      setOpenAccountUrl("https://online.wafdbank.com/link/openaccount");
    } else {
      setOpenAccountUrl("https://forms.fivision.com/wafdbank/oav3?");
    }
  };

  const [customerType, setCustomerType] = useState("");

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h2>Thank you for your interest in opening an account with WaFd Bank.</h2>
        <div className="row mt-4">
          <div className="col-md-6">
            <h5 className="text-success">Let's get started!</h5>
            <FormGroup>
              <Form.Check
                inline
                label={
                  <span>
                    I am a <span className="text-success font-weight-bold">current</span> WaFd Bank customer
                  </span>
                }
                name="currentCustomer"
                className="pl-0"
                type="radio"
                autoComplete="off"
                id={`current-customer-radio-btn`}
                value={"current"}
                checked={customerType === "current"}
                onChange={() => {
                  setCustomerType("current");
                }}
              />
              <Form.Check
                inline
                label={
                  <span>
                    I am a <span className="text-success font-weight-bold">new</span> WaFd Bank customer
                  </span>
                }
                name="newCustomer"
                type="radio"
                autoComplete="off"
                id={`new-customer-radio-btn`}
                className="pl-0"
                value={"new"}
                checked={customerType === "new"}
                onChange={() => {
                  setCustomerType("new");
                }}
              />
            </FormGroup>

            {customerType === "new" && (
              <div className="pl-4 ml-3">
                <NotificationAlert
                  type="primary"
                  id="service-state-notification-alert"
                  bodyText="To open an account with WaFd Bank you must reside in one of the nine western states that we operate in."
                />
                <p className="font-weight-bold">Select the state that you reside in:</p>
                {wafdStates.map((state, index) => (
                  <label key={index} className="d-flex">
                    <Checkbox
                      checkedState={stateClick[index]}
                      onChange={() => handleStateClick(index)}
                      id={`${state.toLowerCase().replace(/ +/g, "-")}-checkbox`}
                    />
                    <span className="cb-label-text">{state}</span>
                  </label>
                ))}
              </div>
            )}
            {stateClick.find((checkbox) => checkbox === true) || customerType === "current" ? (
              <Button
                id="open-account-continue-btn"
                type="anchor"
                url={openAccountUrl}
                class="btn-primary w-100 w-sm-50"
                containerClass="my-4"
                text="Continue"
                showIcon={false}
              />
            ) : (
              <button
                id="open-account-continue-btn-disabled"
                disabled={true}
                className={`my-4 btn btn-primary w-100 w-sm-50`}
              >
                Continue
              </button>
            )}
          </div>
          <div className="d-none d-md-block col-md-6">
            <img className="w-100" src={StateSelectionMap} alt="State Selection Map" />
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default OpenAnAccount;
